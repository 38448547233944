import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import {
  Input,
  Dropdown,
  Form,
  Radio,
  Button,
  Icon,
  Checkbox,
} from "semantic-ui-react";
// import JSMpeg from "@cycjimmy/jsmpeg-player";
// import Messages from './Messages';
// import MessageInput from './MessageInput';
import Peer from "simple-peer";
import AddInfo from "./Components/addInfo";
import NavBar from "./Components/navBar";
import "./App.css";
import axios from "axios";
function App() {
  const [socket, setSocket] = useState(null);
  const ws = useRef(null);
  const [myStrema, setMyStream] = useState(null);
  const myVideo = useRef();
  const userVideo = useRef(null);
  const pc = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [room, setRoom] = useState("foo");
  const [socketRef, setSocketRef] = useState(null);
  const [showStreaming, setShowStreaming] = useState(true);
  const [showImage, setShowImage] = useState("");
  const [userStream, setUserStream] = useState(null);
  const [matchedImage, setImageCapture] = useState(null);
  const [updatefaceId, setUpdatefaceId] = useState("");
  const [notMatchPopu, showNotMatchPopup] = useState(false);
  const [matchPopup, showMatchPopup] = useState(false);
  const [noDevice, setNoDeviceOpen] = useState("");
  // const newSocket = io(`https://liviawebrtsp.appskeeper.in/`);
  const [Ip, setIP] = useState("");
  useEffect(() => {
    setTimeout(() => {
      console.log("current connecttosocet call", !ws.current?.connected);
      if (Ip) connectToSocket();
    }, 1500);
  }, [navigator.onLine, Ip]);
  var room = Ip;

  const connectToSocket = () => {
    // https://socketstg.liviaapp.com/
    // ws.current = io(`https://socketstg.liviaapp.com/`);
    //socketstg.liviaapp.com/
    ws.current = io(`https://liviawebrtsp.appskeeper.in/`, {
      extraHeaders: {
        Authorization: `networkId=${Ip}`,
      },
      transports: ["websocket", "polling"],
      upgrade: false,
      query: `type=browser&deviceId=&networkId=${Ip}`,
    });
    console.log(ws.current.connected, "*******connecting to Socket******");
    console.log("current socket out", ws?.current);
    ws.current.on("connect", () => {
      console.log("current socket in", ws?.current);
      console.log(ws.current.connected, "*******connected******");
      setSocketRef(ws.current);
      // console.log(res, "res");
    });

    ws.current.on("disconnect", (reason) => {
      console.log("current disconnect");
      setTimeout(() => {
        console.log("current disconnect interval");

        if (navigator?.onLine) connectToSocket();
      }, 1000);
      console.log(
        ws.current.connected,
        `*******${reason}****** will attempt to reconnect in 1sec`
      );
    });
    ws.current.on("connect_error", (err) => {
      console.log(err, "*******connect_error******");
      ws.current.close();
    });
  };
  useEffect(() => {
    // navigator.mediaDevices
    //   .getUserMedia({
    //     audio: false,
    //     video: false,
    //   })
    //   .then((stream) => {
    //     // debugger;
    //     setMyStream(stream);
    //     console.log(stream);
    //     myVideo.current.srcObject = stream;
    //   })
    //   .catch((e) => {
    //     // alert("getUserMedia() error: " + e.name);
    //   });
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      debugger;
      console.log(res.data);
      setIP(res?.data?.ip);
    });
  }, []);
  // useEffect(() => {
  //   if (localVideo) {
  //     gotStream();
  //   }
  // }, []);
  // ws.current.on("remoteVideo", (res) => {
  //   // console.log("current socket in", ws?.current);
  //   // console.log(ws.current.connected, "*******connected******");
  //   // setSocketRef(ws.current);
  //   console.log(res, "res");
  // });
  let remoteStream;
  // let pc;
  var isStarted = false;
  var isInitiator = false;
  var isChannelReady = false;

  // let remoteStream;

  function handleRemoteStreamAdded(event) {
    console.log("Remote stream added.", event.stream);
    setLoading(false);
    setUserStream(event.stream);
    userVideo.current.srcObject = event.stream;
  }

  function doCall() {
    console.log("Send1ing offer to peer");
    pc.current.createOffer(setLocalAndSendMessage, handleCreateOfferError);
  }
  function maybeStart() {
    console.log(
      ">>>>>>> maybeStart() ",
      isStarted,
      // localStream,
      isChannelReady
    );
    if (!isStarted && typeof myStrema !== "undefined" && isChannelReady) {
      console.log(">>>>>> creating peer connection");
      createPeerConnection();
      // pc?.current?.addStream(myStrema);
      isStarted = true;
      console.log("isInitiator", isInitiator);
      if (isInitiator) {
        doCall();
      }
    }
  }
  function setLocalAndSendMessage(sessionDescription) {
    pc?.current.setLocalDescription(sessionDescription);
    console.log("setLocalAndSendMessage sending message", sessionDescription);
    sendMessage(sessionDescription);
  }
  function handleCreateOfferError(event) {
    console.log("createOffer() error: ", event);
  }

  function gotStream(stream) {
    console.log("Adding local stream.");
    // localStream = stream;
    // localVideo.srcObject = stream;
    // sendMessage("got user media12", localVideo.srcObject);
    // if (isInitiator) {
    maybeStart();
    // }
  }

  function handleIceCandidate(event) {
    console.log("icecandidate event: ", event);
    if (event.candidate) {
      sendMessage({
        type: "candidate",
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate,
      });
    } else {
      console.log("End of candidates.");
    }
  }
  function createPeerConnection() {
    try {
      pc.current = new RTCPeerConnection();
      pc.current.onicecandidate = handleIceCandidate;
      pc.current.onaddstream = handleRemoteStreamAdded;
      pc.current.onremovestream = handleRemoteStreamRemoved;
      console.log("Created RTCPeerConnnection", pc);
    } catch (e) {
      console.log("Failed to create PeerConnection, exception: " + e.message);
      alert("Cannot create RTCPeerConnection object.");
      return;
    }
  }
  function sendMessage(message = "hi") {
    console.log("Client sending message: ", message);
    ws.current.emit("message", message);
  }

  function handleRemoteStreamRemoved(event) {
    console.log("Remote stream removed. Event: ", event);
    // let peer = new eer();
  }
  // useEffect(() => {
  if (socketRef) {
    console.log("socketRefinUseEffect", socketRef);
    // isChannelReady = true;
    // maybeStart();
    ws.current.on("message", (message) => {
      console.log(message, "ressdaasf", socketRef);
      if (message === "got user media") {
        maybeStart();
        if (message?.id) {
          myVideo.current.srcObject = message;
        }
      } else if (message.type === "offer") {
        // debugger;
        if (!isInitiator && !isStarted) {
          // debugger;
          maybeStart();
        }
        pc?.current?.setRemoteDescription(new RTCSessionDescription(message));
        doAnswer();
      } else if (message.type === "answer" && isStarted) {
        pc.current.setRemoteDescription(new RTCSessionDescription(message));
      } else if (message.type === "candidate" && isStarted) {
        var candidate = new RTCIceCandidate({
          sdpMLineIndex: message.label,
          candidate: message.candidate,
        });
        console.log("candidate", "call", isStarted);
        pc.current.addIceCandidate(candidate);
      } else if (message === "bye" && isStarted) {
        handleRemoteHangup();
        setUserStream(null);
      }

      // remoteStream = message.stream;
      // remoteVideo.srcObject = remoteStream;
    });
    // ws.current.on("remoteVideo", (res) => {
    //   console.log(res, "ressdaasf");
    //   debugger;

    // });
    if (room !== "") {
      ws.current.emit("create or join", room);
      console.log("Attempted to create or  join room", room);
    }

    ws.current.on("join", function (room) {
      console.log("Another peer made a request to join room " + room);
      console.log("This peer is the initiator of room " + room + "!");
      isChannelReady = true;
    });
    ws.current.on("joined", function (room) {
      console.log("joined: " + room);
      isChannelReady = true;
      isInitiator = true;
    });
    ws.current.on("created", function (room) {
      console.log("Created room " + room);
      isInitiator = true;
      isChannelReady = true;
    });

    ws.current.on("captureImageResponse", (res) => {
      console.log("captureImageResponse ", res);
      // isInitiatorc = true;
      // setUserStream(null);
      isStarted = false;
      setLoading(false);
      setImageCapture(res);
      if (res.status === false) showNotMatchPopup(true);
      else if (res.status === true) showMatchPopup(true);
    });

    ws.current.on("playVideoOnAdminResponse", (res) => {
      console.log("capture Image ", res);
      // isInitiator = true;
      setLoading(false);
      setNoDeviceOpen(res?.msg);
    });
  }
  // }, [socketRef]);
  if (socketRef) {
    ws.current.on("updateFaceIdResponse", (res) => {
      console.log(res, "updateFaceIdResponse");
      setLoading(false);
      setUpdatefaceId(res?.msg);

      // setImageCapture(res);
    });
    // ws.current.on("rawImageResponse", (res) => {
    //   console.log(res, "rawImageResponse");
    // });
  }
  function handleRemoteHangup() {
    console.log("Session terminated.");
    stop();

    isInitiator = false;
  }
  function stop() {
    console.log("stop");
    isStarted = false;
    pc.current = null;
  }

  function doAnswer() {
    console.log("Sending answer to peer.");
    pc?.current
      ?.createAnswer()
      .then(setLocalAndSendMessage, onCreateSessionDescriptionError);
  }
  function onCreateSessionDescriptionError(error) {
    console.log("Failed to create session description: " + error.toString());
  }
  console.log(userStream, matchedImage, "userStream");
  return (
    <>
      <NavBar />

      <AddInfo
        setUpdatefaceId={setUpdatefaceId}
        socketRef={socketRef}
        ws={ws}
        isStarted={isStarted}
        notMatchPopu={notMatchPopu}
        matchPopup={matchPopup}
        showNotMatchPopup={showNotMatchPopup}
        showMatchPopup={showMatchPopup}
        noDevice={noDevice}
        userVideo={userVideo}
        userStream={userStream}
        setLoading={setLoading}
        loading={loading}
        matchedImage={matchedImage}
        updatefaceId={updatefaceId}
        setImageCapture={setImageCapture}
        setUserStream={setUserStream}
      />
      {/* <div style={{ textAlign: "center", marginTop: 15 }}>
        <label for="cars">Select insurance company</label>
        <select name="cars" id="cars">
          <option value="volvo">Jubliee</option>
        </select>
      </div> */}
      {/* <div>
        <label for="cars">Select Type of card</label>
        <select name="cars" id="cars">
          <option value="volvo">Liason</option>
        </select>
      </div> */}
      {/* <div
        className="App"
        style={{
          // border: "1px solid",
          width: "70%",
          margin: "0 auto",
          flexDirection: "column",
        }}
      > */}
      {/* {matchedImage && <h2>Captured Image</h2>}
        {matchedImage && (
          <img
            src={matchedImage}
            alt="userImage"
            width="200px"
            height="400px"
          />
        )} */}
      {/* <div
        onClick={() => {
          // const peer = new Peer({
          //   initiator: true,
          //   trickle: false,
          //   stream: myStrema,
          // });
          // peer.on("stream", (stream) => {
          //   userVideo.current.srcObject = stream;
          // });
        }}
      >
        {/* streaming */}
      {/* 
      {myStrema && (
        <video
          width="320"
          ref={myVideo}
          autoPlay
          muted
          // playsInline
          height="240"
        />
      )} */}

      {/* {loading ? <h2>Loading.....</h2> : <></>} */}
      {/* {userStream && !matchedImage ? (
          <video
            width="320"
            ref={userVideo}
            autoPlay
            muted
            // playsInline
            height="240"
          />
        ) : null} */}
      {/* {!userStream && !matchedImage ? (
          <button
            onClick={() => {
              setLoading(true);
              ws?.current?.emit("startVideoRequest", { start: 1 });
            }}
            style={{
              color: "#fff",
              background: "#40a9ff",
              borderColor: "#40a9ff",
              fontWeight: 700,
              padding: 10,
              cursor: "pointer",
              border: "1px solid #40a9ff",
              borderRadius: 5,
            }}
          >
            {loading ? `Processing...` : `Match Face ID`}
          </button>
        ) : (
          <></>
        )}
        {userStream && !matchedImage ? (
          <button
            onClick={() => {
              setLoading(true);
              ws.current.emit("captureImageRequest", {
                start: 1,
                type: "create",
              });
            }}
            style={{
              color: "#fff",
              background: "#40a9ff",
              borderColor: "#40a9ff",
              fontWeight: 700,
              padding: 10,
              cursor: "pointer",
              border: "1px solid #40a9ff",
              borderRadius: 5,
              marginLeft: 10,
              marginTop: 20,
            }}
          >
            {loading ? `Processing...` : `capture Face ID`}
          </button>
        ) : null}
      </div> */}
    </>
  );
}

export default App;
