import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./Addinfo.css";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import {
  Input,
  Dropdown,
  Form,
  Radio,
  Button,
  Icon,
  Checkbox,
  Search,
} from "semantic-ui-react";
// import PropTypes from "prop-types";

// const resultRenderer = ({ entity_id }) => <p>{entity_id}</p>;
// resultRenderer.PropTypes = {
//   display_result: PropTypes.string,
//   //code_description: PropTypes.string,
// };
export default function Index(props) {
  const {
    loading,
    setLoading,
    matchedImage,
    ws,
    setImageCapture,
    socketRef,
    updatefaceId,
    noDevice,
    notMatchPopu,
    matchPopup,
    showNotMatchPopup,
    showMatchPopup,
    setUpdatefaceId,
    isStarted,
    setUserStream,
  } = props;
  const [age, setAge] = React.useState(10);
  const [value, setValue] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [searchLoader, setSearchLoader] = React.useState(false);
  const [searchingIndecator, setSearchIndecator] = React.useState("");
  const [useName, setUserName] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [useInfo, setUserInfo] = React.useState([]);
  const [info, setInfo] = React.useState(false);
  const [machFaceId, showMachFaceId] = React.useState(false);
  const [showProceed, setShowProceed] = React.useState(false);
  const options = [{ key: 1, text: "Face Detection", value: 1 }];
  const [afterNotMatch, setAfterNotMatch] = React.useState(false);
  const [rawImage, setRawImage] = React.useState(null);
  React.useEffect(() => {
    if (props.userStream) setInfo(false);
  }, [props.userStream]);
  const handleSearch = (e, { value }) => {
    if (!value) {
      setSearchIndecator("");
      setSearchLoader(false);
      setSearchValue("");
      return;
    }
    setSearchLoader(true);
    setSearchValue(value);
    setSearchIndecator("searching");
    setSearchLoader(true);
    axios
      .get(
        `https://usa.liviaapp.com/api/search-insured-member?insurance_company_id=16&search=${e.target.value}&is_search_by_smartcard=0&smartcard_provider_name=&for_policy_holder=&from_md_portal=1`,
        {
          headers: {
            "LiviaApp-Token": "3053b7ba0a27bc8e8312cd1f00e1e87f17db59c9",
            "LiviaApp-country": "ke",
            "LiviaApp-language": "en",
            "LiviaApp-city": "186301",
          },
        }
      )
      .then((res) => {
        if (res?.data?.body?.length) {
          setResults(res?.data?.body);
          setSearchIndecator("");
          setSearchLoader(false);
        } else {
          setSearchIndecator("No data found");
          setSearchLoader(false);
        }
      })
      .catch(() => {
        setSearchIndecator("");
        setSearchLoader(false);
      });
  };
  const getInsuranceDetail = () => {
    axios
      .get(
        "https://usa.liviaapp.com/api/insurance-company?limit=1000&offset=0&search=&user_id=42155&type=patient_insurance_company_hospital&entity_id=WPOC-002&insurance_company_id=16&hopital_portal=hopital_portal&list_for_md_claims=list_for_md_claims",
        {
          headers: {
            "LiviaApp-Token": "3053b7ba0a27bc8e8312cd1f00e1e87f17db59c9",
            "LiviaApp-country": "ke",
            "LiviaApp-language": "en",
            "LiviaApp-city": "186301",
          },
        }
      )
      .then((res) => console.log(res));
  };
  const resultRenderer = (data) => {
    return (
      <p>
        {data.beneficiary_name + " | " + data?.phone + " | " + data.entity_id}
      </p>
    );
  };

  const handleResultSelect = (data, { result }) => {
    setSearchValue(result?.entity_id);
    setLoading(false);
    axios
      .get(
        `https://usa.liviaapp.com/api/search-insured-member?insurance_company_id=16&search=${result.entity_id}&is_search_by_smartcard=0&smartcard_provider_name=&for_policy_holder=&from_md_portal=1`,
        {
          headers: {
            "LiviaApp-Token": "3053b7ba0a27bc8e8312cd1f00e1e87f17db59c9",
            "LiviaApp-country": "ke",
            "LiviaApp-language": "en",
            "LiviaApp-city": "186301",
          },
        }
      )
      .then((res) => {
        axios
          .get(
            ` https://usa.liviaapp.com/api/insurance-company?limit=1000&offset=0&search=&user_id=${result.user_id}&type=patient_insurance_company_hospital&entity_id=${result.entity_id}&insurance_company_id=16&hopital_portal=hopital_portal&list_for_md_claims=list_for_md_claims`,
            {
              headers: {
                "LiviaApp-Token": "3053b7ba0a27bc8e8312cd1f00e1e87f17db59c9",
                "LiviaApp-country": "ke",
                "LiviaApp-language": "en",
                "LiviaApp-city": "186301",
              },
            }
          )
          .then((res) => {});
        setUserInfo(res?.data?.body);
        setInfo(!res?.data?.body[0]?.division);
        if (res?.data?.body[0]?.division) {
          showMachFaceId(true);
        }
      });
  };
  //   resultRenderer.PropTypes = {
  //     display_result: PropTypes.string,
  //     //code_description: PropTypes.string,
  //   };

  /*********Socket */
  // React.useEffect(() => {
  if (socketRef) {
    props?.ws?.current?.on("rawImageResponse", (res) => {
      console.log(res, "rawImageResponse");
      setRawImage(res);
    });
    props?.ws?.current?.on("connectionSocketId", (res) => {
      console.log(res, "connectionSocketId");
      // setRawImage(res);
    });
  }
  // }, [socketRef]);

  return (
    <div className="container">
      <h2
        className="text-blue bold"
        style={{ marginLeft: "8%", marginTop: "-28px" }}
      >
        Create Claim
      </h2>
      <div className="addInfo">
        <h4 style={{ color: "#53c392" }}>Add Info</h4>
        <div className="videoAndField">
          <div className="leftContainer">
            <div
              className="firstRow row mt15"
              style={{ alignItems: "flex-end" }}
            >
              <div className="field">
                <div>Claim Date</div>
                <input
                  type="text"
                  disabled
                  className="inputField"
                  value={moment().format("DD-MM-yyyy")}
                />
              </div>
              <div className="field">
                <div>Patient Insurance</div>

                <Dropdown
                  placeholder="Select Friend"
                  fluid
                  selection
                  options={options}
                />
              </div>
              <div className="field">
                <div>Patient Name</div>
                <input
                  type="text"
                  disabled
                  className="inputField"
                  value={
                    useInfo?.length ? useInfo[0]?.beneficiary_first_name : ""
                  }
                />
              </div>
            </div>
            <div className="firstRow row mt15">
              <div className="image-after-radio-cover field">
                <Radio
                  //   disabled={fieldsDisabled}
                  label="Search with Smart card"
                  name="radioGroup"
                  value="1"
                  checked={true}
                  //   onChange={onSearchWithChange}
                />
              </div>
              <div className="field">
                <div>Smart Card no.</div>
                <Search
                  // disabled={this.props.disabled}
                  loading={searchLoader}
                  onResultSelect={handleResultSelect}
                  onSearchChange={handleSearch}
                  results={results}
                  noResultsMessage={searchingIndecator}
                  value={searchValue}
                  resultRenderer={resultRenderer}
                  placeholder={"Search"}
                />
                {/* <input value={value} onChange={(e) => setValue(e.target.value)} />{" "}
            <button onClick={getInsuranceDetail}>Search</button> */}
              </div>
              <div className="field">
                <div>Gender</div>
                <input
                  type="text"
                  disabled
                  className="inputField"
                  value={useInfo?.length ? useInfo[0]?.sex : ""}
                />
              </div>
            </div>

            <div className="firstRow row mt15">
              <div className="field">
                <div>Employer name *</div>
                <input
                  type="text"
                  disabled
                  className="inputField"
                  value={
                    useInfo?.length ? useInfo[0]?.beneficiary_first_name : ""
                  }
                />
              </div>
              <div className="field">
                <div>Phone no *</div>
                <input
                  type="text"
                  disabled
                  className="inputField"
                  value={useInfo?.length ? useInfo[0]?.phone : ""}
                />
              </div>
              <div className="field">
                <div>Birthday</div>
                <input
                  type="text"
                  disabled
                  className="inputField"
                  value={
                    useInfo?.length
                      ? moment(useInfo[0]?.dob).format("DD-MM-YYYY")
                      : ""
                  }
                />
              </div>
              {/* <div className="field">
            <div>Claim Date</div>
            <input type="text" disabled className="inputField" />
          </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="addInfo" style={{ marginTop: 20 }}>
        {props.userStream ? (
          <div className="rightContainer">
            <h3>
              Kindly proceed to capture the face image to whom {searchValue}{" "}
              belongs
            </h3>

            <video
              // width="320"
              className="videoStream"
              ref={props.userVideo}
              autoPlay
              muted
              style={{ width: "100%", height: 480, objectfit: "conatain" }}
              // playsInline
              height="240"
            />

            <button
              className={`btn btn-primary mt10 ${loading ? "on-load" : ""}`}
              onClick={() => {
                setLoading(true);
                props.ws.current.emit("captureImageRequest", {
                  start: 1,
                  type: useInfo[0]?.division ? "match" : "create",
                });
              }}
            >
              capture Face ID
              <div className="ui active dimmer">
                <div className="ui loader"></div>
              </div>
            </button>
          </div>
        ) : null}
        {rawImage ? (
          <div className="rightContainer">
            <img
              src={"data:image/png;base64, " + rawImage?.img}
              style={{ width: 200 }}
            />
            <button
              className={`btn btn-primary mt10 ${loading ? "on-load" : ""}`}
              onClick={() => {
                setLoading(true);
                props.ws.current.emit("captureImageRequest", {
                  start: 1,
                  type: useInfo[0]?.division ? "match" : "create",
                });
              }}
            >
              capture Face ID
              <div className="ui active dimmer">
                <div className="ui loader"></div>
              </div>
            </button>
          </div>
        ) : (
          ""
        )}
        {/* {matchedImage?.msg ? <p>{matchedImage?.msg}</p> : ""} */}
        {machFaceId &&
        !props.userStream &&
        !matchedImage &&
        !info &&
        !rawImage ? (
          <div className="rightContainer" style={{ justifyContent: "center" }}>
            {noDevice ? <p>{noDevice}</p> : ""}
            <button
              className={`btn btn-primary mt10 ${loading ? "on-load" : ""}`}
              onClick={() => {
                setLoading(true);
                props.ws?.current?.emit("startVideoRequest", {
                  start: 1,
                });
              }}
            >
              Match Face ID
              <div className="ui active dimmer">
                <div className="ui loader"></div>
              </div>
            </button>
          </div>
        ) : (
          <></>
        )}
        {/* {afterNotMatch ? (
          <div className="rightContainer" style={{ justifyContent: "center" }}>
            {noDevice ? <p>{noDevice}</p> : ""}
            <button
              className={`btn btn-primary mt10 ${loading ? "on-load" : ""}`}
              onClick={() => {
                setLoading(true);
                props.ws?.current?.emit("startVideoRequest", {
                  start: 1,
                });
              }}
            >
              Match Face ID
              <div className="ui active dimmer">
                <div className="ui loader"></div>
              </div>
            </button>
          </div>
        ) : (
          <></>
        )} */}

        {showProceed ? (
          <div className="rightContainer" style={{ justifyContent: "center" }}>
            {noDevice ? <p>{noDevice}</p> : ""}
            <button
              className={`btn btn-primary mt10 ${loading ? "on-load" : ""}`}
              // onClick={() => {
              //   setLoading(true);
              //   props.ws?.current?.emit("startVideoRequest", {
              //     start: 1,
              //   });
              // }}
            >
              Proceed
              <div className="ui active dimmer">
                <div className="ui loader"></div>
              </div>
            </button>
          </div>
        ) : (
          <></>
        )}
        {matchedImage && !useInfo[0]?.division ? (
          <div className="rightContainer" style={{ justifyContent: "center" }}>
            <img
              src={matchedImage?.imagePath}
              alt="anadks"
              width="200px"
              height="300px"
            />
            {updatefaceId ? updatefaceId : <></>}
            {!updatefaceId && (
              <button
                className={`btn btn-primary mt10 ${loading ? "on-load" : ""}`}
                onClick={() => {
                  setLoading(true);
                  props.ws?.current?.emit("updateFaceId", {
                    entityId: searchValue,
                    faceId: matchedImage?.faceId,
                  });
                }}
              >
                Update Face ID
                <div className="ui active dimmer">
                  <div className="ui loader"></div>
                </div>
              </button>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {info && !props.userStream && useInfo && !matchedImage && (
        <div className="popup-cover">
          <div
            className="add-medicine-popup popup-ui sick-note-popup"
            style={{ width: "650px" }}
          >
            <div className="popup-header">
              <h2 style={{ marginBottom: 0 }}>Info</h2>
              <Icon
                name="close"
                className="close-popup"
                onClick={() => setInfo(false)}
              ></Icon>
            </div>
            <div className="popup-body">
              This patient does not have face data registered in our database.
              Kindly proceed to register this patient before providing your
              services.
              <p style={{ color: "red" }}>
                <b>
                  Please note:- Once the Face ID is linked to the patient card,
                  it can't be changed. Please ask the patient if the card
                  belongs to them.
                </b>
              </p>
            </div>
            <div className="popup-footer">
              <button
                style={{ width: 80 }}
                className={`btn btn-primary ${loading ? "on-load" : ""}`}
                onClick={() => {
                  setLoading(true);
                  props.ws?.current?.emit("startVideoRequest", {
                    start: 1,
                  });
                }}
              >
                Ok
                <div className="ui active dimmer">
                  <div className="ui loader"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}

      {matchPopup && (
        <div className="popup-cover">
          <div
            className="add-medicine-popup popup-ui sick-note-popup"
            style={{ width: "650px" }}
          >
            <div className="popup-header">
              <h2 style={{ marginBottom: 0 }}>Info</h2>
              <Icon
                name="close"
                className="close-popup"
                onClick={() => showMatchPopup(false)}
              ></Icon>
            </div>
            <div className="popup-body">
              <h2 style={{ textAlign: "center" }}>{matchedImage?.msg}</h2>
            </div>
            <div className="popup-footer">
              <button
                style={{ width: 80 }}
                className={`btn btn-primary ${loading ? "on-load" : ""}`}
                onClick={() => {
                  showMatchPopup(false);
                  setShowProceed(true);
                  setUserStream(null);
                  setRawImage(null);
                }}
              >
                Ok
                <div className="ui active dimmer">
                  <div className="ui loader"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {notMatchPopu && (
        <div className="popup-cover">
          <div
            className="add-medicine-popup popup-ui sick-note-popup"
            style={{ width: "650px" }}
          >
            <div className="popup-header">
              <h2 style={{ marginBottom: 0 }}>Info</h2>
              <Icon
                name="close"
                className="close-popup"
                onClick={() => showNotMatchPopup(false)}
              ></Icon>
            </div>
            <div className="popup-body">
              <h2 style={{ textAlign: "center" }}>{matchedImage?.msg}</h2>
            </div>
            <div className="popup-footer">
              <button
                style={{ width: 80 }}
                className={`btn btn-primary ${loading ? "on-load" : ""}`}
                onClick={() => {
                  showNotMatchPopup(false);
                  setAfterNotMatch(true);
                  setImageCapture(null);
                  setUserStream(null);
                  setRawImage(null);
                }}
              >
                Ok
                <div className="ui active dimmer">
                  <div className="ui loader"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {updatefaceId && (
        <div className="popup-cover">
          <div
            className="add-medicine-popup popup-ui sick-note-popup"
            style={{ width: "650px" }}
          >
            <div className="popup-header">
              <h2 style={{ marginBottom: 0 }}>Info</h2>
              <Icon
                name="close"
                className="close-popup"
                onClick={() => setUpdatefaceId(false)}
              ></Icon>
            </div>
            <div className="popup-body">
              <h2 style={{ textAlign: "center" }}>{updatefaceId}</h2>
            </div>
            <div className="popup-footer">
              <button
                style={{ width: 80 }}
                className={`btn btn-primary ${loading ? "on-load" : ""}`}
                onClick={() => {
                  setUpdatefaceId(false);
                  setImageCapture(null);
                  setUserStream(null);
                  // isStarted = false;
                }}
              >
                Ok
                <div className="ui active dimmer">
                  <div className="ui loader"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
