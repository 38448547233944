import React from "react";
import "./Navbar.css";
import clsx from "clsx";
import ErrorImage from "../../asset/logo-white.svg";
export default function Index() {
  return (
    <div
      className="header-cover space-between"
      style={{ height: 63, alignItems: "center", display: "flex" }}
    >
      <div className="pointer cursor-pointer">
        <div
          className={clsx({
            "image-cover": true,
            "logo-image-cover": true,
            // "circular-image": !hospitalLogoError && getHospitalLogoInLS(),
          })}
          style={{ height: 50 }}
        >
          <img
            alt="hospital-logo"
            src={ErrorImage}
            style={{ height: "100%" }}
          />
        </div>
        {/* <ImageViewerHospitalLogo
                src={myProfileApiData?.hospital_avatar}
              /> */}
      </div>
      <div className="right-portion">
        <div className="menu-options-list"></div>
      </div>
    </div>
  );
}
